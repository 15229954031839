import React from 'react';

import {AppBaseContainer} from '@medad-sep/core';

import playgroundConfig from '../../applet-playground';
import {config} from '../config';
import {useAppFonts} from '../shared/useAppFonts';

import {PreviewContainer} from './components/PreviewContainer';
import {usePreviewValues} from './utils/usePreviewValues';

export default function AppletPlayground() {
  const [fontLoaded] = useAppFonts();
  const {isAppletReady, previewValues} = usePreviewValues();

  if (!isAppletReady) return null;

  const isDemoMode = playgroundConfig.isDemoMode;

  return (
    <AppBaseContainer
      {...previewValues}
      baseAppContext={{isDemoMode, apiConfig: {}} as any}
      isReady={fontLoaded}
      tenant={config.tenant}
      demoMode={isDemoMode}>
      <PreviewContainer {...previewValues} />
    </AppBaseContainer>
  );
}
