import React, {useEffect, useRef, useState} from 'react';

import {ApiConnector, App, AppletDefinition} from '@medad-sep/core';
import {PagesItems} from '@medad-sep/shell-default/components/PagesList';
import {getServicePage} from '@medad-sep/shell-default/components/ServicePage';

import playgroundConfig from '../../../applet-playground';
import {Main} from '../components/Main';
import {AppletPreviewValues} from '../models';

import {withPreviewView} from './withPreviewView';

export function usePreviewValues(): {isAppletReady: boolean; previewValues: AppletPreviewValues} {
  const [isAppletReady, setIsAppletReady] = useState(false);

  const values = useRef<{
    app?: App;
    appletsList?: AppletDefinition[];
    connectors?: ApiConnector[];
    screens?: any[];
    Main?: any;
  }>({});

  useEffect(() => {
    const applet: AppletDefinition = playgroundConfig.applet as any;
    const connector: ApiConnector = playgroundConfig.connector as any;

    values.current = {
      appletsList: [applet],
      connectors: [{...connector, id: applet.id, enabled: true}],
      app: {
        id: 'playground',
        primaryColor: '#9b9b9b',
        accentColor: '#8a1222',
        structure: {pages: [], launcher: {}, auxiliaryPages: []},
      },
      screens: [],
    };

    const pageItems: PagesItems = [];

    applet.load().then(({default: applet}) => {
      applet.widgets.forEach((it) => {
        it.Component = withPreviewView(it.Component);
      });

      [...applet.services, ...applet.widgets].forEach((it) => {
        values.current.screens?.push(getServicePage(it));
        pageItems.push({id: it.id, icon: it.Icon || 'Hexagon', title: it.name});
      });

      values.current.Main = () => <Main pageItems={pageItems} />;

      setIsAppletReady(true);
    });
  }, []);

  return {isAppletReady, previewValues: values.current};
}
