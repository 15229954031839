import {registerRootComponent} from 'expo';
import {LogBox} from 'react-native';

// Needed for nanoid like packages that depends on crypto
import 'react-native-get-random-values';

import App from './App';

LogBox.ignoreLogs(['Setting a timer for a long period of time']);
LogBox.ignoreLogs(['forwardRef render functions accept exactly two parameters']);

registerRootComponent(App);
