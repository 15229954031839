import React from 'react';

import {StatusBar} from 'expo-status-bar';

import {SafeAreaView, Theme, useAppTheme} from '@medad-sep/core';
import {MainStackNavigator, MainStackScreen} from '@medad-sep/shell-default/components/MainStackNavigator';

export function PreviewContainer({screens, Main}: {screens?: any; Main?: any}) {
  const {theme} = useAppTheme();
  return (
    <SafeAreaView>
      <StatusBar style={theme === Theme.light ? 'dark' : 'light'} />
      <MainStackNavigator>
        <MainStackScreen name="main" component={Main} options={{headerShown: false}} />
        {screens}
      </MainStackNavigator>
    </SafeAreaView>
  );
}
