import * as BackgroundFetch from 'expo-background-fetch';
import ky from 'ky';

import {appletsList} from '@medad-sep/config';
import {
  defineBackgroundTask,
  calendarSyncBackgroundTask,
  ApiConnector,
  loadApplets,
  AuthResponse,
  cacheService,
  authCacheKey,
  initApplets,
  AppletUpdateReasons,
  calendarService,
  syncCalendarEvents,
} from '@medad-sep/core';

import {config} from '../config';

const {baseApiUrl, tenant} = config;

export function initSyncCalendarEvents() {
  defineBackgroundTask(calendarSyncBackgroundTask, async () => {
    try {
      const {connectors} = await ky
        .get('connector-manager/connectors', {
          prefixUrl: baseApiUrl,
          headers: {'x-tenant': tenant},
        })
        .json<{connectors: ApiConnector[]}>();

      const applets = await loadApplets(appletsList, connectors);

      const userAuthInfo: AuthResponse | undefined = await cacheService.get(authCacheKey);

      if (!userAuthInfo) return BackgroundFetch.BackgroundFetchResult.NoData;

      try {
        await initApplets(
          applets,
          connectors,
          true,
          userAuthInfo.accessToken,
          tenant,
          AppletUpdateReasons.UPDATE_CALENDAR,
        );
      } catch (error) {
        return BackgroundFetch.BackgroundFetchResult.Failed;
      }

      const calendarEvents = calendarService.getEvents();

      if (calendarEvents.length) {
        syncCalendarEvents(calendarEvents);
      }

      return BackgroundFetch.BackgroundFetchResult.NewData;
    } catch (error) {
      return BackgroundFetch.BackgroundFetchResult.Failed;
    }
  });
}
