// todo: check if we need to remove it for production
import 'expo-dev-client';

import React from 'react';

import {QueryClient, QueryClientProvider} from 'react-query';

import {AuthProvider} from '@medad-sep/core';

// todo : remove when this is released https://github.com/software-mansion/react-native-gesture-handler/pull/2404
import 'setimmediate';

import './src/localization';

import playgroundConfig from './applet-playground';
import {config} from './src/config';
import AppletPlayground from './src/Playground';
import {AppVersion} from './src/shared/AppVersion';
import ShellContainer from './src/ShellContainer';

const queryClient = new QueryClient({
  defaultOptions: {queries: {refetchOnWindowFocus: false, retry: 0}},
});

const Content = playgroundConfig.applet ? AppletPlayground : ShellContainer;

export default function App() {
  return (
    <AuthProvider config={config.ssoConfigs}>
      <QueryClientProvider client={queryClient}>
        <Content />
      </QueryClientProvider>

      <AppVersion />
    </AuthProvider>
  );
}
