import {InitOptions} from 'i18next';

import {config} from '../config';

import {ar} from './ar';
import {en} from './en';

export const i18nOptions: InitOptions = {
  resources: {
    en,
    ar,
  },
  lng: config.defaultAppLang,
  fallbackLng: 'en',
  ns: ['translation'],
};
