import Constants from 'expo-constants';

import {EnvironmentProfile} from '@medad-sep/core';

let configOverride = (window as any)?.configOverride ?? {ssoConfigs: {}};

if (Constants.expoConfig?.extra?.configOverride) {
  try {
    configOverride = JSON.parse(Constants.expoConfig?.extra?.configOverride);
  } catch (e) {}
}

const ssoConfigsOverride = configOverride.ssoConfigs ?? {};

const baseApiUrl = 'https://api-sep.medadstg.com';
const ssoUrl = 'https://sso.medadstg.com/realms/medad';

export const config = {
  baseApiUrl: baseApiUrl,
  defaultAppLang: 'en',
  tenant: 'medad',
  environmentProfile: EnvironmentProfile.PRODUCTION,
  ...configOverride,
  ssoConfigs: {
    url: ssoConfigsOverride.baseUrl || ssoUrl,
    clientId: 'sep-client',
    ...ssoConfigsOverride,
  },
};
