import {Local} from './Local';

export const en: Local = {
  translation: {
    language: {
      en: 'English',
      ar: 'العربية',
    },
    logout: 'Log out',
  },
};
