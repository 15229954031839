import {useFonts} from 'expo-font';

export function useAppFonts() {
  return useFonts({
    'NewSansArabic-Regular': require('../../assets/fonts/NewSansArabic-Regular.ttf'),
    'NewSansArabic-Bold': require('../../assets/fonts/NewSansArabic-Bold.ttf'),
    'NewSansArabic-Medium': require('../../assets/fonts/NewSansArabic-Medium.ttf'),
    NewSansArabic: require('../../assets/fonts/NewSansArabic-Regular.ttf'),
  });
}
