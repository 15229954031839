import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/ar';

import {i18nOptions} from './options';

i18n.use(initReactI18next).init({
  ...i18nOptions,
});
