import React, {useCallback} from 'react';

import {NavigationState} from '@react-navigation/native';

import {appletsList} from '@medad-sep/config';
import {useApp, useConnectors, AppBaseContainer, EventHub, AppEvents, useCachedUserLang} from '@medad-sep/core';
import Shell from '@medad-sep/shell-default';

import {config} from './config';
import {useAppFonts} from './shared/useAppFonts';
import {initSyncCalendarEvents} from './utils/initSyncCalendarEvents';

const {baseApiUrl, tenant, environmentProfile} = config;

const appEventHub = new EventHub();

initSyncCalendarEvents();

export default function ShellContainer() {
  const {data: app} = useApp(baseApiUrl, tenant, environmentProfile);
  const {data: connectors} = useConnectors(baseApiUrl, tenant);

  const isUserSettingsLoaded = useCachedUserLang();

  const [fontLoaded] = useAppFonts();

  const onNavigationChange = useCallback((state: NavigationState | undefined) => {
    appEventHub.emit(AppEvents.ROUTE, state?.routes[state.index]?.name);
  }, []);

  return (
    <AppBaseContainer
      app={app}
      connectors={connectors}
      baseAppContext={{
        apiConfig: {baseApiUrl, tenant, environmentProfile},
        appEventHub,
      }}
      tenant={tenant}
      onNavigationChange={onNavigationChange}
      isReady={fontLoaded && isUserSettingsLoaded}
      appletsList={appletsList}>
      <Shell />
    </AppBaseContainer>
  );
}
